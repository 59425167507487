* {
    box-sizing: border-box;
    font-family: "Helvetica", sans-serif;
    margin:0;
    padding:0;
    box-sizing: border-box;
  }
  
  .container {
    text-align: center;
    padding: 15px;
    margin: 10px auto;
  }
  
  .csv-input {
    padding: 10px;
    display: block;
    margin: 15px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .container p {
    padding-top: 10px;
    color: #666;
  }

#wrapper{
    width:100%;
}

#item{
    width:100%;
    display:flex;
}

#item>div{
    border: 1px solid black;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
}

#download{
    margin:30px;
}

#download>a{
    text-decoration:none;
}
  